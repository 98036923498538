import axios from 'axios'
import qs from 'qs'
import router from './router'

axios.interceptors.request.use(function (config) {
    
    let token = localStorage.getItem("token");
    if (token == undefined) {
        token = "";
    }
    config.headers.Authorization = "Bearer "+token;
    return config;
}, function (error) {
    return console.log(error);
});

axios.interceptors.response.use(res => {
    return res;
  }, (err) => {
    
    try{
        if(err.response.status==401){
            localStorage.removeItem("token");
            localStorage.removeItem("member");
            router.push("/noLogin")
        }else{
            router.push("/fail")
        }
    }catch{
        router.push("/fail")
    }
})

export const uri = "";
// export const uri = "http://localhost:5000";
// export const uri = "https://test.mtpc.cc";


export const pushExlReq = (file, callback) => postFile('/Task/NewPushExl', file, callback);
export const pushPraiseReq = (file, callback) => postFile('/Order/PushPraise', file, callback);

//会员相关
export const announcementGetResources = (params, callback) => get('/Announcement/GetResources', params, callback);
export const memberGet = (params, callback) => get('/Member/Get', params, callback);

export const memberGetCommission = (params, callback) => get('/Member/GetCommission', params, callback);
export const memberGetConfig = (params, callback) => get('/Member/GetConfig', params, callback);


export const memberGetMemberIdGet = (params, callback) => get('/Member/GetMemberId', params, callback);
export const memberRead = (params, callback) => get('/Member/Read', params, callback);
export const memberReadAll = (params, callback) => get('/Member/ReadAll', params, callback);

export const memberSetLogin = (params, callback) => get('/Member/SetLogin', params, callback);
export const memberSendSmsSetPhone = (params, callback) => get('/Member/SendSmsSetPhone', params, callback);
export const memberSetMobile = (params, callback) => get('/Member/SetMobile', params, callback);
export const memberLogin = (params, callback) => get('/Member/Login', params, callback);
export const memberSendSms = (params, callback) => get('/Member/SendSms', params, callback);
export const memberRegister = (params, callback) => post('/Member/Register', params, callback);
export const sendSmsRecover = (params, callback) => get('/Member/sendSmsRecover', params, callback);
export const loginWithCode = (params, callback) => get('/Member/LoginWithCode', params, callback);
export const memberGetInvoiceInfo = (params, callback) => get('/Member/GetInvoiceInfo', params, callback);


export const shopGet = (params, callback) => get('/Shop/Get', params, callback);
export const shopGetSel = (params, callback) => get('/Shop/GetSel', params, callback);

export const areaGet = (params, callback) => get('/Shop/GetArea', params, callback);
export const shopAdd = (params, callback) => post('/Shop/Add', params, callback);
export const shopUpdate = (params, callback) => post('/Shop/Update', params, callback);
export const shopReReview = (params, callback) => post('/Shop/ReReview', params, callback);
export const shopGetPraiseCost = (params, callback) => get('/Shop/GetPraiseCost', params, callback);

export const accountTransfer = (params, callback) => get('/Account/Transfer', params, callback);
export const accountGetTarget = (params, callback) => get('/Account/GetTarget', params, callback);

export const accountGetAccount = (params, callback) => get('/Account/GetAccount', params, callback);
export const accountGetCommission = (params, callback) => get('/Account/GetCommission', params, callback);
export const topUpGet = (params, callback) => get('/Account/GetTopUp', params, callback);
export const withdrawGet = (params, callback) => get('/Account/GetWithdraw', params, callback);
export const topUp = (params, callback) => post('/Account/TopUp', params, callback);
export const withdrawNew = (params, callback) => post('/Account/WithdrawNew', params, callback);
export const recordGet = (params, callback) => get('/Account/GetRecord', params, callback);
export const getCollection = (params, callback) => get('/Account/GetCollection', params, callback);



export const taskDel = (params, callback) => get('/Task/Del', params, callback);

export const taskGetConsole = (params, callback) => get('/Task/GetConsole', params, callback);
export const taskGetOrder = (params, callback) => get('/Task/GetOrder', params, callback);
export const orderSetPraise = (params, callback) => post('/Order/SetPraise', params, callback);
export const taskGet = (params, callback) => get('/Task/Get', params, callback);
export const taskGetDetail = (params, callback) => get('/Task/GetDetail', params, callback);
export const taskPreCreate = (params, callback) => post('/Task/PreCreate', params, callback);
export const toolGetPicture = (params, callback) => get('/Tool/GetPicture', params, callback);
export const taskGetLatelyTask = (params, callback) => get('/Task/GetLatelyTask', params, callback);
export const taskGetInfoByUrl = (params, callback) => get('/Task/GetInfoByUrl', params, callback);
export const taskImportTaskByUrl = (params, callback) => post('/Task/ImportTask', params, callback);
export const taskPush = (params, callback) => post('/Task/Push', params, callback);
export const taskGetShopReportDownloadReq = (params, callback) => get('/Task/GetShopReportDownload', params, callback);
export const taskCopyListReq = (params, callback) => get('/Task/CopyList', params, callback);

export const taskOverTaskReq = (params, callback) => get('/Task/OverTask', params, callback);

export const taskGetReceiptOrderReq = (params, callback) => get('/Task/GetReceiptOrder', params, callback);
export const taskGetReceiptShopReq = (params, callback) => get('/Task/GetReceiptShop', params, callback);


export const taskChangeIsPause = (params, callback) => get('/Task/ChangeIsPause', params, callback);
export const taskSetTime = (params, callback) => get('/Task/SetTime', params, callback);
export const taskChangeType = (params, callback) => get('/Task/ChangeType', params, callback);
export const taskGetDetailConsole = (params, callback) => get('/Task/GetDetailConsole', params, callback);

export const taskEditDetailNum = (params, callback) => get('/Task/EditDetailNum', params, callback);
export const taskGetOrderByDetailId = (params, callback) => get('/Task/GetOrderByDetailId', params, callback);
export const taskExistDetail = (params, callback) => post('/Task/ExistDetail', params, callback);

export const orderGetReview = (params, callback) => get('/Order/GetReview', params, callback);
export const orderOverPrincipal = (params, callback) => post('/Order/OverPrincipal', params, callback);
 
export const orderGetOutsideCashierFunds = (params, callback) => get('/Order/GetOutsideCashierFunds', params, callback);
export const orderLock = (params, callback) => get('/Order/Lock', params, callback);
export const orderCompletePay = (params, callback) => get('/Order/CompletePay', params, callback);
export const orderGetReviewOrder = (params, callback) => get('/order/GetReviewOrder', params, callback);
export const orderRefuse = (params, callback) => get('/order/Refuse', params, callback);
export const orderPass = (params, callback) => get('/order/Pass', params, callback);
export const orderCancel = (params, callback) => get('/order/Cancel', params, callback);
export const orderSetMark = (params, callback) => get('/order/SetMark', params, callback);
export const orderSavePraise = (params, callback) => post('/order/SavePraise', params, callback);
export const orderGetPraiseOrder = (params, callback) => get('/task/GetPraiseOrder', params, callback);

export const orderPraisePass = (params, callback) => get('/order/PraisePass', params, callback);
export const orderPraiseRefuse = (params, callback) => get('/order/PraiseRefuse', params, callback);


export const taskGetTask = (params, callback) => get('/Task/GetTask', params, callback);
export const pay = (params, callback) => get('/Task/Pay', params, callback);
export const taskCopyTask = (params, callback) => get('/Task/CopyTask', params, callback);
export const getConfig = (params, callback) => get('/Member/GetConfig', params, callback);


export const announcementGet = (params, callback) => get('/Announcement/Get', params, callback);

export const promoteGetIncome = (params, callback) => get('/Promote/GetIncome', params, callback);
export const promoteGetMember = (params, callback) => get('/Promote/GetMember', params, callback);

 
export const get = (api, params, callback) => axios.get(uri + api, {
    params: params
}).then(res => {
    callback(res.data);
});

export const post = (api, params, callback) => axios.post(uri + api, qs.stringify(params))
.then(res => {
        callback(res.data);
});
///上传文件
export const postFile = (api, file, callback) => axios.post(uri + api, file,{'Content-type' : 'multipart/form-data'})
.then(res => {
    callback(res.data);
});